import React, { Component } from "react"
import { Swipeable } from "react-swipeable"
import { Link as Scroll } from "react-scroll"
import { Link } from "gatsby"
import { connect } from "react-redux"
import Toggle from "react-toggle"
import { Checkbox } from "@atlaskit/checkbox"
import ReactNotification from "react-notifications-component"
import "react-notifications-component/dist/theme.css"
import { store } from "react-notifications-component"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PageLink from "../components/pagelink"
import Question from "../components/question"
import ListView from "../components/listview"

import { dataBIWS } from "../data/output"
import { dataOutside } from "../data/output-outside-guide"
import { dataFIG } from "../data/output-fig"
import { dataRX } from "../data/output-rx"
import { dataPE } from "../data/output-pe"

import { getQuestions, getUsers } from "../state/app"

import Select from "react-select"

import "../components/layout.scss"
import "./practice.scss"
import "../components/question.scss"
import "../components/collection.scss"
import "react-toggle/style.css"

const isMobile =
  typeof navigator !== "undefined" &&
  (/Android|Mobi|webOS|iPhone|iPad|iPod|BlackBerry|Windows Phone|ZuneWP7|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  ) ||
    navigator.userAgent.indexOf("Mobile") > 0)

const dataAllQuestions = dataOutside
  .concat(dataBIWS)
  .concat(dataFIG)
  .concat(dataRX)
  .concat(dataPE)

const dataAccounting = dataOutside
  .filter(question => question.type === "Accounting")
  .concat(dataBIWS.filter(question => question.type === "Accounting"))

const dataEV = dataOutside
  .filter(question => question.type === "Enterprise & Equity Value")
  .concat(
    dataBIWS.filter(question => question.type === "Enterprise & Equity Value")
  )

const dataValuation = dataOutside
  .filter(question => question.type === "Valuation")
  .concat(dataBIWS.filter(question => question.type === "Valuation"))

const dataDCF = dataOutside
  .filter(question => question.type === "DCF")
  .concat(dataBIWS.filter(question => question.type === "DCF"))

const dataMerger = dataOutside
  .filter(question => question.type === "Merger Model (M&A)")
  .concat(dataBIWS.filter(question => question.type === "Merger Model (M&A)"))

const dataLBO = dataOutside
  .filter(question => question.type === "Leveraged Buyouts (LBOs)")
  .concat(
    dataBIWS.filter(question => question.type === "Leveraged Buyouts (LBOs)")
  )

const dataBrain = dataOutside.filter(
  question => question.type === "Brain Teasers / Misc."
)

class Practice extends Component {
  initialDataSet = {
    name: "All Questions",
    description: "All combined questions",
    dataset: dataAllQuestions,
  }

  state = {
    data: this.initialDataSet.dataset, // used for filtering
    unalteredData: this.initialDataSet.dataset, // master copy
    index: 0, // used to flip through questions array
    selectedCollection: this.initialDataSet.name, // selected collection name
    selectedCollectionDescription: this.initialDataSet.description, // selected collection description
    notification: false,
    difficultyLoading: false,
    basicDifficulty: true,
    advancedDifficulty: true,
    visible: false, // whether answer is visible
    random: false, // whether questions are randomized
    cardView: true,
    listView: false,
    instructions: false,
    instructionsText: "Show Instructions",
  }

  componentDidMount() {
    document.addEventListener("keydown", this.handleKeyDown)
    if (this.props.isLoggedIn) {
      this.props.getQuestions(this.props.user)
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.isLoggedIn !== prevProps.isLoggedIn) {
      this.props.getQuestions(this.props.user)
    }
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyDown)
  }

  hideAnswer = () => {
    this.setState({ visible: false })
  }

  movePrevQuestion = e => {
    this.hideAnswer()
    let length = this.state.data.length
    let i = this.state.index
    if (i === 0) {
      i = length - 1
    } else {
      i -= 1
    }
    this.setState({ index: i })
  }

  moveNextQuestion = e => {
    this.hideAnswer()
    let length = this.state.data.length
    let i = this.state.index
    if (i === length - 1) {
      i = 0
    } else {
      i += 1
    }
    this.setState({ index: i })
  }

  // Flip through questions with arrow keys
  handleKeyDown = e => {
    if (
      this.state.listView ||
      (typeof window !== "undefined" &&
        document.getElementById("feedbackMsg") === document.activeElement)
    ) {
      return
    }

    let keyCode = e.which || e.keyCode

    // Prevent scroll on spacebar
    if (keyCode === 32) {
      e.preventDefault()
    }

    switch (keyCode) {
      // Left arrow key
      case 37:
        this.movePrevQuestion()
        break

      // Right arrow key
      case 39:
        this.moveNextQuestion()
        break

      default:
        break
    }
  }

  // Shuffle & unshuffle datasets
  toggleRandom = () => {
    this.setState({ random: !this.state.random })
    let { random } = this.state

    if (!random) {
      this.setState({ data: this.shuffle(this.state.data) })
    } else {
      if (this.state.basicDifficulty && !this.state.advancedDifficulty) {
        this.setState({
          data: this.state.unalteredData.filter(
            question => question.subType === "Basic"
          ),
        })
      } else if (!this.state.basicDifficulty && this.state.advancedDifficulty) {
        this.setState({
          data: this.state.unalteredData.filter(
            question => question.subType !== "Basic"
          ),
        })
      } else if (this.state.basicDifficulty && this.state.advancedDifficulty) {
        this.setState({ data: this.state.unalteredData })
      }
    }
  }

  // Shuffle dataset (uses Fisher-Yates/Knuth Shuffle)
  shuffle = array => {
    var currentIndex = array.length,
      temporaryValue,
      randomIndex

    // Don't edit original array (pure)
    let newArr = [...array]

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex)
      currentIndex -= 1

      // And swap it with the current element.
      temporaryValue = newArr[currentIndex]
      newArr[currentIndex] = newArr[randomIndex]
      newArr[randomIndex] = temporaryValue
    }

    return newArr
  }

  triggerInstructions = () => {
    let { instructions } = this.state

    if (instructions) {
      this.setState({
        instructions: false,
        instructionsText: "Show Instructions",
      })
    } else {
      this.setState({
        instructions: true,
        instructionsText: "Hide Instructions",
      })
    }
  }

  shuffleOnCollectionChange = () => {
    if (this.state.random) {
      this.setState({ data: this.shuffle(this.state.data) })
    }
  }

  // Set state with which collection is selected
  getSelectedCollection = name => {
    this.setState({ selectedCollection: name.value, index: 0 })

    switch (name.value) {
      case "Outside the Guide":
        this.setState(
          {
            data: dataOutside,
            unalteredData: dataOutside,
            selectedCollectionDescription:
              "Questions from outside BIWS 400 and WSO",
          },
          () => this.shuffleOnCollectionChange()
        )
        break

      case "Private Equity / Buyside Recruiting":
        this.setState(
          {
            data: dataPE,
            unalteredData: dataPE,
            selectedCollectionDescription: "Questions for buyside recruiting",
          },
          () => this.shuffleOnCollectionChange()
        )
        break

      case "IB Fundamentals":
        this.setState(
          {
            data: dataBIWS,
            unalteredData: dataBIWS,
            selectedCollectionDescription: "All BIWS technical questions",
          },
          () => this.shuffleOnCollectionChange()
        )
        break

      case "Financial Institutions Group (FIG)":
        this.setState(
          {
            data: dataFIG,
            unalteredData: dataFIG,
            selectedCollectionDescription: "FIG specific questions",
          },
          () => this.shuffleOnCollectionChange()
        )
        break

      case "Restructuring (Rx) / Distressed M&A":
        this.setState(
          {
            data: dataRX,
            unalteredData: dataRX,
            selectedCollectionDescription: "Rx specific questions",
          },
          () => this.shuffleOnCollectionChange()
        )
        break

      // By Type
      case "Accounting":
        this.setState(
          {
            data: dataAccounting,
            unalteredData: dataAccounting,
            selectedCollectionDescription: "All accounting questions",
          },
          () => this.shuffleOnCollectionChange()
        )
        break

      case "Enterprise & Equity Value":
        this.setState(
          {
            data: dataEV,
            unalteredData: dataEV,
            selectedCollectionDescription:
              "All Enterprise & Equity Value questions",
          },
          () => this.shuffleOnCollectionChange()
        )
        break

      case "Valuation":
        this.setState(
          {
            data: dataValuation,
            unalteredData: dataValuation,
            selectedCollectionDescription: "All Valuation questions",
          },
          () => this.shuffleOnCollectionChange()
        )
        break

      case "DCF":
        this.setState(
          {
            data: dataDCF,
            unalteredData: dataDCF,
            selectedCollectionDescription: "All DCF questions",
          },
          () => this.shuffleOnCollectionChange()
        )
        break

      case "Merger Model (M&A)":
        this.setState(
          {
            data: dataMerger,
            unalteredData: dataMerger,
            selectedCollectionDescription: "All Merger Model (M&A) questions",
          },
          () => this.shuffleOnCollectionChange()
        )
        break

      case "Leveraged Buyouts (LBOs)":
        this.setState(
          {
            data: dataLBO,
            unalteredData: dataLBO,
            selectedCollectionDescription:
              "All Leveraged Buyouts (LBOs) questions",
          },
          () => this.shuffleOnCollectionChange()
        )
        break

      case "Miscellaneous / Finance Brain Teasers":
        this.setState(
          {
            data: dataBrain,
            unalteredData: dataBrain,
            selectedCollectionDescription: "All brain teaser questions",
          },
          () => this.shuffleOnCollectionChange()
        )
        break

      case "All Questions":
        this.setState(
          {
            data: dataAllQuestions,
            unalteredData: dataAllQuestions,
            selectedCollectionDescription: "All questions",
          },
          () => this.shuffleOnCollectionChange()
        )
        break

      default:
        break
    }

    if (
      name.value === "Outside the Guide" ||
      name.value === "Financial Institutions Group (FIG)" ||
      name.value === "Restructuring (Rx) / Distressed M&A" ||
      name.value === "Miscellaneous / Finance Brain Teasers" ||
      name.value === "Private Equity / Buyside Recruiting"
    ) {
      this.setState({ basicDifficulty: false, advancedDifficulty: true })
    } else {
      this.setState({ basicDifficulty: true, advancedDifficulty: true })
    }
  }

  setDifficulty = e => {
    let { name: n } = e.target
    this.setState({ difficultyLoading: true })

    if (
      (n === "Basic" &&
        this.state.basicDifficulty &&
        !this.state.advancedDifficulty) ||
      (n === "Advanced" &&
        this.state.advancedDifficulty &&
        !this.state.basicDifficulty)
    ) {
      if (this.state.notification) {
        return
      }
      this.setState({ notification: true })
      store.addNotification({
        message: "You must have at least one difficulty level selected.",
        type: "default",
        container: "center",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 2000,
          onScreen: true,
        },
      })

      setTimeout(() => this.setState({ notification: false }), 3000)
      return
    }

    let val = e.currentTarget.checked

    if (n === "Basic") {
      this.setState({ basicDifficulty: !this.state.basicDifficulty }, () => {
        if (this.state.basicDifficulty && !this.state.advancedDifficulty) {
          this.setState({
            data: this.state.data.filter(
              question => question.subType === "Basic"
            ),
          })
        } else if (
          !this.state.basicDifficulty &&
          this.state.advancedDifficulty
        ) {
          this.setState({
            data: this.state.data.filter(
              question => question.subType !== "Basic"
            ),
          })
        } else if (
          this.state.basicDifficulty &&
          this.state.advancedDifficulty
        ) {
          this.setState({ data: this.state.unalteredData })
        }
      })
    } else if (n === "Advanced") {
      this.setState(
        { advancedDifficulty: !this.state.advancedDifficulty },
        () => {
          if (this.state.basicDifficulty && !this.state.advancedDifficulty) {
            this.setState({
              data: this.state.data.filter(
                question => question.subType === "Basic"
              ),
            })
          } else if (
            !this.state.basicDifficulty &&
            this.state.advancedDifficulty
          ) {
            this.setState({
              data: this.state.data.filter(
                question => question.subType !== "Basic"
              ),
            })
          } else if (
            this.state.basicDifficulty &&
            this.state.advancedDifficulty
          ) {
            this.setState({ data: this.state.unalteredData })
          }
        }
      )
    }
  }

  setTab = tab => {
    if (tab === "list") {
      this.setState({ listView: true, cardView: false })
    } else {
      this.setState({ cardView: true, listView: false })
    }
  }

  render() {
    let data = this.state.data
    let i = this.state.index

    // Select Dropdown Options
    // const options = [
    //   { value: "Outside the Guide", label: "Outside the Guide Collection" },
    //   { value: "BIWS Guide", label: "BIWS Guide Collection" },
    //   { value: "WSO Guide", label: "WSO Guide Collection" },
    // ]

    const options = [
      {
        label: "By Collection (4)",
        options: [
          { label: "Outside the Guide", value: "Outside the Guide" },
          { label: "IB Fundamentals", value: "IB Fundamentals" },
          {
            label: "Private Equity / Buyside Recruiting",
            value: "Private Equity / Buyside Recruiting",
          },
          {
            label: "Miscellaneous / Finance Brain Teasers",
            value: "Miscellaneous / Finance Brain Teasers",
          },
        ],
      },
      {
        label: "By Topic (8)",
        options: [
          { label: "Accounting", value: "Accounting" },
          {
            label: "Enterprise & Equity Value",
            value: "Enterprise & Equity Value",
          },
          {
            label: "Valuation",
            value: "Valuation",
          },
          { label: "DCF", value: "DCF" },
          { label: "Merger Model (M&A)", value: "Merger Model (M&A)" },
          {
            label: "Leveraged Buyouts (LBOs)",
            value: "Leveraged Buyouts (LBOs)",
          },
          {
            label: "Financial Institutions Group (FIG)",
            value: "Financial Institutions Group (FIG)",
          },
          {
            label: "Restructuring (Rx) / Distressed M&A",
            value: "Restructuring (Rx) / Distressed M&A",
          },
        ],
      },
      {
        label: "Other (1)",
        options: [{ label: "All Questions", value: "All Questions" }],
      },
    ]

    let cardView = []
    // if (isMobile) {
    //   cardView.push(
    //     <div className="instructions flex-nav mb-16">
    //       <span className="mr-8">
    //         <Lozenge maxWidth="600" appearance="inprogress">
    //           SWIPE OR USE NEXT/PREV TO CYCLE QUESTIONS
    //         </Lozenge>
    //       </span>
    //       <span>
    //         <Lozenge appearance="inprogress">
    //           TAP SAVE TO SAVE QUESTIONS
    //         </Lozenge>
    //       </span>
    //     </div>
    //   )
    // } else if (!isMobile) {
    //   cardView.push(
    //     <div className="instructions flex-nav mb-16">
    //       <span className="mr-8">
    //         <Lozenge maxWidth="600" appearance="inprogress">
    //           USE ARROW KEYS TO CYCLE QUESTIONS
    //         </Lozenge>
    //       </span>
    //       <span>
    //         <Lozenge maxWidth="600" appearance="inprogress">
    //           USE SPACE BAR TO QUICKLY TOGGLE ANSWER
    //         </Lozenge>
    //       </span>
    //     </div>
    //   )
    // }
    cardView.push(
      <>
        <Swipeable
          onSwipedRight={this.movePrevQuestion}
          onSwipedLeft={this.moveNextQuestion}
          preventDefaultTouchmoveEvent={true}
        >
          <Question
            fullQ={data[i]}
            topic={data[i].type}
            question={data[i].question}
            answer={data[i].answer}
            source={data[i].source}
            credit={data[i].credit}
            difficulty={data[i].subType}
            id={data[i].id}
            visible={this.state.visible}
            user={this.props.user}
            selectedCollection={this.state.selectedCollection}
          />
        </Swipeable>
        <div className="controls">
          <span onClick={this.movePrevQuestion}>{"« Prev"}</span>
          <span onClick={this.moveNextQuestion}>{"Next »"}</span>
        </div>
      </>
    )

    return (
      <Layout location={this.props.location} crumbLabel="Practice">
        <SEO
          title="Practice Questions"
          description="400+ crowdsourced practice investment banking technical interview questions segmented by topic, including accounting, enterprise and equity value, valuation, DCF, merger models, and leveraged buyouts."
        />
        <div className="center-notifications">
          <ReactNotification />
        </div>
        <div className="page">
          <div className="content practice-header">
            <h1 className="header-1 v-align">Practice Questions </h1>
          </div>
          <span
            onClick={this.triggerInstructions}
            className="content instructions-msg active-link"
          >
            {this.state.instructionsText + ". "}
          </span>
          {!this.props.isLoggedIn && [
            <span className="content mt-8">
              <Link className="active-link" to="/sign-in">
                Login
              </Link>{" "}
              to save questions that you want to return to later.
            </span>,
          ]}
          {this.props.isLoggedIn && (
            <span className="content mt-8">
              You have{" "}
              <Link className="active-link" to="/saved-questions">
                <b>{this.props.firebaseQuestions.length}</b> question
                {this.props.firebaseQuestions.length !== 1 ? "s" : null} saved.
              </Link>
            </span>
          )}
          {this.state.instructions && (
            <div className="content instructions mt-16">
              {isMobile ? (
                <>
                  <div className="instruction">
                    <span>Next Question</span>
                    <span>Swipe ←</span>
                  </div>
                  <div className="instruction">
                    <span>Previous Question</span>
                    <span>Swipe →</span>
                  </div>
                </>
              ) : (
                <>
                  <div className="instruction">
                    <span>Next Question</span>
                    <span>→ ARROW KEY</span>
                  </div>
                  <div className="instruction">
                    <span>Previous Question</span>
                    <span>← ARROW KEY</span>
                  </div>
                </>
              )}
              {!isMobile && (
                <div className="instruction">
                  <span>Show Answer</span>
                  <span>SPACE BAR</span>
                </div>
              )}
              {this.props.isLoggedIn && (
                <div className="instruction">
                  <span>Save Question</span>
                  <span>SAVE</span>
                </div>
              )}
            </div>
          )}
          <br />
          <br />
          {/* <div className="header-2">Select Your Collection</div>
        <div className="content">
          <b>Collection Description:</b>{" "}
          {this.state.selectedCollectionDescription}
        </div>
        */}
          <Select
            blurInputOnSelect={true}
            options={options}
            placeholder="Filter Questions"
            // defaultValue={options[2].options[0]}
            onChange={this.getSelectedCollection}
            isSearchable={false}
            className="react-select-container"
            classNamePrefix="react-select"
            theme={theme => ({
              ...theme,
              borderRadius: 8,
              colors: {
                ...theme.colors,
                primary25: "whitesmoke",
                primary50: "whitesmoke",
                primary75: "whitesmoke",
                primary: "#005dff",
              },
            })}
          />
          <div className="more-controls">
            <div className="difficulty-controls">
              <div className="difficulty-label mb-8">
                Difficulty
                {(this.state.selectedCollection === "Outside the Guide" ||
                  this.state.selectedCollection ===
                    "Financial Institutions Group (FIG)" ||
                  this.state.selectedCollection ===
                    "Restructuring (Rx) / Distressed M&A" ||
                  this.state.selectedCollection ===
                    "Miscellaneous / Finance Brain Teasers" ||
                  this.state.selectedCollection ===
                    "Private Equity / Buyside Recruiting") && (
                  <span>{" - advanced only"}</span>
                )}
              </div>
              <div className="difficulty-levels">
                <Checkbox
                  onChange={this.setDifficulty}
                  isChecked={this.state.basicDifficulty}
                  isDisabled={
                    this.state.selectedCollection === "Outside the Guide" ||
                    this.state.selectedCollection ===
                      "Financial Institutions Group (FIG)" ||
                    this.state.selectedCollection ===
                      "Restructuring (Rx) / Distressed M&A" ||
                    this.state.selectedCollection ===
                      "Miscellaneous / Finance Brain Teasers" ||
                    this.state.selectedCollection ===
                      "Private Equity / Buyside Recruiting"
                  }
                  defaultChecked
                  label="Basic"
                  value="Basic"
                  name="Basic"
                />
                <Checkbox
                  onChange={this.setDifficulty}
                  isChecked={
                    this.state.advancedDifficulty ||
                    this.state.selectedCollection === "Outside the Guide" ||
                    this.state.selectedCollection ===
                      "Financial Institutions Group (FIG)" ||
                    this.state.selectedCollection ===
                      "Restructuring (Rx) / Distressed M&A" ||
                    this.state.selectedCollection ===
                      "Miscellaneous / Finance Brain Teasers" ||
                    this.state.selectedCollection ===
                      "Private Equity / Buyside Recruiting"
                  }
                  isDisabled={
                    this.state.selectedCollection === "Outside the Guide" ||
                    this.state.selectedCollection ===
                      "Financial Institutions Group (FIG)" ||
                    this.state.selectedCollection ===
                      "Restructuring (Rx) / Distressed M&A" ||
                    this.state.selectedCollection ===
                      "Miscellaneous / Finance Brain Teasers" ||
                    this.state.selectedCollection ===
                      "Private Equity / Buyside Recruiting"
                  }
                  defaultChecked
                  label="Advanced"
                  value="Advanced"
                  name="Advanced"
                />
              </div>
            </div>
            <div className="content tabs mt-16">
              <span
                className={
                  this.state.cardView
                    ? "content tab tab-selected"
                    : "content tab"
                }
                onClick={this.setTab.bind(this, "card")}
              >
                Card View
              </span>
              <span
                className={
                  this.state.listView
                    ? "content tab tab-selected ml-16"
                    : "content tab ml-16"
                }
                onClick={this.setTab.bind(this, "list")}
              >
                List View
              </span>
            </div>
          </div>
          <div className="content toggle-random">
            <Toggle
              id="randomize"
              defaultChecked={this.state.random}
              onChange={this.toggleRandom}
            />
            <span className="ml-8">
              Randomize{this.state.random ? "d" : null}
            </span>
          </div>
          {this.state.cardView && cardView}
          {this.state.listView && <ListView data={this.state.data} />}
          {/* <div className="content center">
          <Scroll
            to="top"
            className="btn-ghost"
            spy={true}
            smooth={true}
            duration={500}
          >
            Scroll to Top
          </Scroll>
        </div> */}
        </div>
      </Layout>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.app.user,
    isLoggedIn: state.app.isLoggedIn,
    firebaseQuestions: state.app.firebaseQuestions,
    loading: state.app.loading,
  }
}

export default connect(mapStateToProps, { getQuestions, getUsers })(Practice)
