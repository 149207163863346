import React, { Component } from "react"
import "./question.scss"
import { Link as Scroll } from "react-scroll"
import uuid from "uuid"

import { connect } from "react-redux"
import { addQuestion, removeQuestion } from "../state/app"

import "./listview.scss"

class ListView extends Component {
  handleSave = (question, saveContent) => {
    let q = {
      type: question.type,
      question: question.question,
      collection: question.collection,
      answer: question.answer,
      id: question.id,
      user: this.props.user,
    }

    if (saveContent === "SAVE") {
      this.props.addQuestion(q)
    } else {
      this.props.removeQuestion(q)
    }
  }

  render() {
    let { firebaseQuestions } = this.props

    let listView = this.props.data.map((question, i) => {
      // If question already saved, show unsave feature
      let index = firebaseQuestions.findIndex(el => el.id === question.id)
      let saveContent
      if (index === -1) {
        saveContent = "SAVE"
      } else {
        saveContent = "UNSAVE"
      }

      return (
        <React.Fragment key={uuid.v4()}>
          <div className="mb-4" key={uuid.v4()}>
            <div className="flex-between">
              <span className="header-4 v-align">{"Question " + (i + 1)} </span>
              {this.props.isLoggedIn && (
                <span
                  key={uuid.v4()}
                  unselectable="on"
                  className="unsave ml-8 hoverable v-align"
                  onClick={this.handleSave.bind(this, question, saveContent)}
                >
                  {saveContent}
                </span>
              )}
            </div>
            <div className="mb-8 list-badges">
              <span className="lozenge lozenge-green">{question.type}</span>
              {(question.collection == "Outside the Guide" ||
                question.collection == "IB Fundamentals") && (
                <span className="lozenge lozenge-green ml-16">
                  {question.collection}
                </span>
              )}
              {question.subType !== "FIG" &&
                question.subType !== "Rx" &&
                question.subType !== "Private Equity" && (
                  <span className="lozenge lozenge-green ml-16 lozenge-difficulty">
                    {question.subType.length > 0
                      ? question.subType
                      : "Advanced"}
                  </span>
                )}
            </div>
            <div className="header-3" key={uuid.v4()}>
              {question.question}
            </div>
            <p key={uuid.v4()}>{question.answer}</p>
          </div>
        </React.Fragment>
      )
    })

    return (
      <>
        <div className="hide-x-overflow">
          <div className="content mt-8">
            <span className="lozenge">{this.props.data.length} Questions</span>
            <div className="mt-8"></div>
            {listView}
          </div>
          <div className="content center">
            <Scroll
              to="top"
              className="btn"
              spy={true}
              smooth={true}
              duration={500}
            >
              Scroll to Top
            </Scroll>
          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    firebaseQuestions: state.app.firebaseQuestions,
    isLoggedIn: state.app.isLoggedIn,
    user: state.app.user,
  }
}

export default connect(mapStateToProps, { addQuestion, removeQuestion })(
  ListView
)
