import React, { Component } from "react"
import "./question.scss"

import { connect } from "react-redux"
import { addQuestion, removeQuestion } from "../state/app"

let saveContent

class Question extends Component {
  state = {
    visible: false,
  }

  componentDidMount() {
    document.addEventListener("keydown", this.handleKeyDown)
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyDown)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ visible: nextProps.visible })
  }

  // Show Answer on spacebar
  handleKeyDown = e => {
    let keyCode = e.which || e.keyCode

    if (
      keyCode === 32 &&
      typeof window !== `undefined` &&
        !(document.getElementById("feedbackMsg") === document.activeElement)
    ) {
      this.toggleAnswer()
    }
  }

  toggleAnswer = () => {
    this.setState({ visible: !this.state.visible })
  }

  handleSave = () => {
    let question = {
      type: this.props.topic,
      question: this.props.question,
      answer: this.props.answer,
      collection: this.props.fullQ.collection,
      id: this.props.id,
      user: this.props.user,
    }

    if (saveContent === "SAVE") {
      this.props.addQuestion(question)
    } else {
      this.props.removeQuestion(question)
    }
  }

  render() {
    let { firebaseQuestions, source, selectedCollection } = this.props

    // If question already saved, show unsave feature
    let index = firebaseQuestions.findIndex(el => el.id === this.props.id)

    if (index === -1) {
      saveContent = "SAVE"
    } else {
      saveContent = "UNSAVE"
    }

    return (
      <div className="question">
        <div className="question-first-row">
          <div className="header-4">{this.props.topic}</div>
          {this.props.isLoggedIn && (
            <div className="unsave" onClick={this.handleSave}>
              {saveContent}
            </div>
          )}
        </div>
        <div className="header-4">Question</div>
        <div className="header-3">{this.props.question}</div>
        <div className={this.state.visible ? null : "invisible"}>
          <div className="header-4">Answer</div>
          <div className="header-3 answer">{this.props.answer}</div>
        </div>
        <div className="content">
          <button onClick={this.toggleAnswer} className="btn btn-answer">
            {this.state.visible ? "Hide" : "Show"} Answer
          </button>
        </div>
        {/* {this.props.fullQ.collection !== "Outside the Guide" ? (
          <div className="content credit">
            Source:{" "}
            <a
              href={
                this.props.fullQ.collection ===
                "Private Equity / Buyside Recruiting"
                  ? "https://quizlet.com/subject/private-equity-interview-questions/"
                  : "https://quizlet.com/subject/investment-banking-interview-questions/"
              }
              target="_blank"
              className="credit-link"
            >
              Various Public Quizlet Sets
            </a>
          </div>
        ) : (
          <div className="content credit">
            Source:{" "}
            <a
              href="https://bankingatmichigan.org"
              target="_blank"
              className="credit-link"
            >
              Banking at Michigan
            </a>
            {" and "}
            <a
              href="https://www.westerninvestmentclub.ca/"
              target="_blank"
              className="credit-link"
            >
              Western Ivey's Western Investment Club
            </a>
          </div>
        )} */}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    firebaseQuestions: state.app.firebaseQuestions,
    isLoggedIn: state.app.isLoggedIn,
  }
}

export default connect(mapStateToProps, { addQuestion, removeQuestion })(
  Question
)
